import validate from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/node_modules/nuxt/dist/pages/runtime/validate.js";
import maintenance_45global from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/middleware/maintenance.global.ts";
import manifest_45route_45rule from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  maintenance_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  admin: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/middleware/admin.ts"),
  "agreed-responsable-terms": () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/middleware/agreed-responsable-terms.ts"),
  authenticated: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/middleware/authenticated.ts"),
  guest: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/middleware/guest.ts"),
  "old-search-redirections": () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/middleware/old-search-redirections.ts")
}