import { default as accessibiliteGbecSO0D1uMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/accessibilite.vue?macro=true";
import { default as _91slug_93IbK79V4VO5Meta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/activites/[slug].vue?macro=true";
import { default as editba6dtf5ZvvMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/contenus/activites/[id]/edit.vue?macro=true";
import { default as indexSuCoiDGl01Meta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/contenus/activites/[id]/index.vue?macro=true";
import { default as addU7s2ZdIc43Meta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/contenus/activites/add.vue?macro=true";
import { default as indexaFHMgiRTp4Meta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/contenus/activites/index.vue?macro=true";
import { default as editI8DUtw9m2BMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/contenus/domaines/[id]/edit.vue?macro=true";
import { default as addUP3KeyRzNsMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/contenus/domaines/add.vue?macro=true";
import { default as index8APmwzgprbMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/contenus/domaines/index.vue?macro=true";
import { default as editZr24pgVJR0Meta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/contenus/modeles-mission/[id]/edit.vue?macro=true";
import { default as addSgz5ehxJY7Meta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/contenus/modeles-mission/add.vue?macro=true";
import { default as indexFyEryBKTjeMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/contenus/modeles-mission/index.vue?macro=true";
import { default as editaNtW7hBW7uMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/contenus/reseaux/[id]/edit.vue?macro=true";
import { default as indexTa1VTJnjuZMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/contenus/reseaux/[id]/index.vue?macro=true";
import { default as addiHobtq8Ji6Meta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/contenus/reseaux/add.vue?macro=true";
import { default as indexRAdx9gpKIzMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/contenus/reseaux/index.vue?macro=true";
import { default as editHdeTXNUenVMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/contenus/ressources/[id]/edit.vue?macro=true";
import { default as addA484RCsuN9Meta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/contenus/ressources/add.vue?macro=true";
import { default as indextVEqehXNeMMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/contenus/ressources/index.vue?macro=true";
import { default as editulJc7xj0XTMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/contenus/territoires/[id]/edit.vue?macro=true";
import { default as indexEvLv1MZPlHMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/contenus/territoires/[id]/index.vue?macro=true";
import { default as add1aJCV5fy9TMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/contenus/territoires/add.vue?macro=true";
import { default as indexHOayaJTzMEMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/contenus/territoires/index.vue?macro=true";
import { default as editLyNrzNG258Meta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/contenus/testimonials/[id]/edit.vue?macro=true";
import { default as index1ksGEPZVWOMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/contenus/testimonials/index.vue?macro=true";
import { default as indextzNWbb3cLUMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/index.vue?macro=true";
import { default as benevoles_45informationsI0wz0vBHY7Meta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/missions/[id]/benevoles-informations.vue?macro=true";
import { default as benevolesLl9vBSaQUJMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/missions/[id]/benevoles.vue?macro=true";
import { default as datesjI1MdicvkkMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/missions/[id]/dates.vue?macro=true";
import { default as indexWx2K9qPACqMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/missions/[id]/index.vue?macro=true";
import { default as informationsMM5PxVyPNnMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/missions/[id]/informations.vue?macro=true";
import { default as lieux8Z8FkQcdCnMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/missions/[id]/lieux.vue?macro=true";
import { default as responsablesyp2tf0P1UNMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/missions/[id]/responsables.vue?macro=true";
import { default as titlefzWMvXINyMMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/missions/[id]/title.vue?macro=true";
import { default as trouver_45des_45benevolesLKbpCN3mXVMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/missions/[id]/trouver-des-benevoles.vue?macro=true";
import { default as visuelBmBYsksht2Meta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/missions/[id]/visuel.vue?macro=true";
import { default as indexJ86KKZ8s6rMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/missions/index.vue?macro=true";
import { default as notesitNUMexM7GMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/notes.vue?macro=true";
import { default as editnnBUdC0OmhMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/organisations/[id]/edit.vue?macro=true";
import { default as indexI5MNd2WYQCMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/organisations/[id]/index.vue?macro=true";
import { default as add8KqeILywl5Meta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/organisations/[id]/missions/add.vue?macro=true";
import { default as indexGLl7ftHas3Meta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/organisations/index.vue?macro=true";
import { default as invitationsJTbaAt4XtNMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/other/invitations.vue?macro=true";
import { default as logsQ53mkMWZjbMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/other/logs.vue?macro=true";
import { default as indexXTXZyQxiSjMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/participations/index.vue?macro=true";
import { default as ressourcesWt84sALuTgMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/ressources.vue?macro=true";
import { default as emailsNqvQnh7qVyMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/settings/emails.vue?macro=true";
import { default as generalKKvZzegFYYMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/settings/general.vue?macro=true";
import { default as messagess4UcmkoHSZMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/settings/messages.vue?macro=true";
import { default as editDTreb1IzwAMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/settings/rules/[id]/edit.vue?macro=true";
import { default as pending_45itemsRkbTOXnpyRMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/settings/rules/[id]/pending-items.vue?macro=true";
import { default as addCQXTSFesDOMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/settings/rules/add.vue?macro=true";
import { default as indexC6EgFNEcb6Meta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/settings/rules/index.vue?macro=true";
import { default as trafic_45entrantmUl5jmmeZoMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/statistics/api-engagement/trafic-entrant.vue?macro=true";
import { default as trafic_45sortant0kkuCMinZYMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/statistics/api-engagement/trafic-sortant.vue?macro=true";
import { default as conversionscF9Ui5io5BMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/statistics/conversions.vue?macro=true";
import { default as missions_45a_45validerEfK8ZsdxcaMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/statistics/departements/missions-a-valider.vue?macro=true";
import { default as missions_45perimeesYWXSueFprSMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/statistics/departements/missions-perimees.vue?macro=true";
import { default as organisations_45a_45validerVwLvCHxk7LMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/statistics/departements/organisations-a-valider.vue?macro=true";
import { default as indexafz1jgfcoeMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/statistics/index.vue?macro=true";
import { default as indicateurs_45clesFL0OneSz7bMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/statistics/indicateurs-cles.vue?macro=true";
import { default as missionsVcpxWKZPHYMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/statistics/missions.vue?macro=true";
import { default as indexQPpQ2Ys7u9Meta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/statistics/organisations/index.vue?macro=true";
import { default as missions_45perimeeskBmWL7vwUJMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/statistics/organisations/missions-perimees.vue?macro=true";
import { default as participations_45a_45validerSDoImJChFLMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/statistics/organisations/participations-a-valider.vue?macro=true";
import { default as participations_45refusees_45annuleesqw8Ah5c9XBMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/statistics/organisations/participations-refusees-annulees.vue?macro=true";
import { default as participations8O6XjDdnyVMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/statistics/participations.vue?macro=true";
import { default as placesz9JV2xpLjbMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/statistics/places.vue?macro=true";
import { default as moderationsSr4SNIyzg5Meta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/statistics/suivis/moderations.vue?macro=true";
import { default as utilisateursbuZ52yisvpMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/statistics/utilisateurs.vue?macro=true";
import { default as edit8VWuCbyuIbMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/taxonomies/[slug]/[id]/edit.vue?macro=true";
import { default as addHuzqqiq5eKMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/taxonomies/[slug]/add.vue?macro=true";
import { default as indexvxhvPg3EJjMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/taxonomies/[slug]/index.vue?macro=true";
import { default as temoignagesluqAu0PEZzMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/temoignages.vue?macro=true";
import { default as edit4EJNrwx7Y5Meta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/utilisateurs/[id]/edit.vue?macro=true";
import { default as indexQpf0wTn9mQMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/utilisateurs/[id]/index.vue?macro=true";
import { default as indexNV0G9uF9PuMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/utilisateurs/index.vue?macro=true";
import { default as benevolesoz9ZNTgh67Meta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/charte-bon-fonctionnement/benevoles.vue?macro=true";
import { default as indexk6T4zcvTZ2Meta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/charte-bon-fonctionnement/index.vue?macro=true";
import { default as responsablesD8tIyCGLwaMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/charte-bon-fonctionnement/responsables.vue?macro=true";
import { default as charte_45reserve_45civique6n5rrn3RbwMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/charte-reserve-civique.vue?macro=true";
import { default as conditions_45generales_45d_45utilisationvVPdcVrWzoMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/conditions-generales-d-utilisation.vue?macro=true";
import { default as indexP013I2MabzMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/dashboard/index.vue?macro=true";
import { default as _91slug_93OKviSZfD3jMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/departements/[slug].vue?macro=true";
import { default as _91slug_938J29JsqjOHMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/domaines-action/[slug].vue?macro=true";
import { default as en_45ce_45moment1TPwYzyhMQMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/en-ce-moment.vue?macro=true";
import { default as indexVhBfgHlSizMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/index.vue?macro=true";
import { default as indexKWxfd6hWYvMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/inscription/benevole/index.vue?macro=true";
import { default as competencesvJlYa9Fv9CMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/inscription/benevole/step/competences.vue?macro=true";
import { default as disponibilites8zuSXnD3dDMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/inscription/benevole/step/disponibilites.vue?macro=true";
import { default as preferencesyoRdqFCqQuMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/inscription/benevole/step/preferences.vue?macro=true";
import { default as profileDx2iKWNMF8Meta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/inscription/benevole/step/profile.vue?macro=true";
import { default as indexasXdRLSBIFMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/inscription/index.vue?macro=true";
import { default as indexw4ae4tfCYHMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/inscription/responsable/index.vue?macro=true";
import { default as charte_45bon_45fonctionnementAB0bDTfsA4Meta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/inscription/responsable/step/charte-bon-fonctionnement.vue?macro=true";
import { default as collectivite_45confirmation6aogiFgBKpMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/inscription/responsable/step/collectivite-confirmation.vue?macro=true";
import { default as collectivitewxOWRN25KvMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/inscription/responsable/step/collectivite.vue?macro=true";
import { default as organisation_45confirmationDLo5Pp3LgWMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/inscription/responsable/step/organisation-confirmation.vue?macro=true";
import { default as organisation_45detailsrtNstWtMDUMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/inscription/responsable/step/organisation-details.vue?macro=true";
import { default as organisation_45imageswNf0gX4IA6Meta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/inscription/responsable/step/organisation-images.vue?macro=true";
import { default as organisation9JgoQwL7eSMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/inscription/responsable/step/organisation.vue?macro=true";
import { default as profilen98HMQi55FMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/inscription/responsable/step/profile.vue?macro=true";
import { default as _91hash_93agdPfIKNPrMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/invitations/[hash].vue?macro=true";
import { default as loginpBxbv48oRCMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/login.vue?macro=true";
import { default as admino2Yf7CTWjcMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/maintenance/admin.vue?macro=true";
import { default as indexIDkgWvopmYMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/maintenance/index.vue?macro=true";
import { default as mentions_45legalesc4o5uImeFOMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/mentions-legales.vue?macro=true";
import { default as _91id_93v2hCGoXZ2IMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/messages/[id].vue?macro=true";
import { default as index8j05Tp35wpMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/messages/index.vue?macro=true";
import { default as addeJNczDjJ2qMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/messages/modeles/add.vue?macro=true";
import { default as indextH4Hj8WLLjMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/messages/modeles/index.vue?macro=true";
import { default as _91slug_93f33Aa4GzSMMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/missions-benevolat/[id]/[slug].vue?macro=true";
import { default as indexXYNt6sJAsAMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/missions-benevolat/[id]/index.vue?macro=true";
import { default as indexIEzEVqIvXbMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/missions-benevolat/index.vue?macro=true";
import { default as notificationspJX0GnXU1pMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/notifications.vue?macro=true";
import { default as _91slug_93iwAQhzmwaZMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/organisations/[slug].vue?macro=true";
import { default as indexyNDBrXJdl9Meta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/organisations/index.vue?macro=true";
import { default as _91token_937Sth7jcFnZMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/password-reset/[token].vue?macro=true";
import { default as indexOIOyqvqAixMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/password-reset/index.vue?macro=true";
import { default as plan_45du_45siteMjlU4PBX0ZMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/plan-du-site.vue?macro=true";
import { default as politique_45de_45confidentialite6T1GEPBjgQMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/politique-de-confidentialite.vue?macro=true";
import { default as charte_45bon_45fonctionnement3fOsCuchXPMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/profile/charte-bon-fonctionnement.vue?macro=true";
import { default as editOOjQ9e3mraMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/profile/edit.vue?macro=true";
import { default as indextb6mxiqUwqMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/profile/index.vue?macro=true";
import { default as missionsFVJfKW6lsJMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/profile/missions.vue?macro=true";
import { default as notificationsM8b93iRAlNMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/profile/notifications.vue?macro=true";
import { default as preferencesnYM7HPvMhVMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/profile/preferences.vue?macro=true";
import { default as settingsRJDkuQJfTWMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/profile/settings.vue?macro=true";
import { default as decembre_45ensembleXhwxrX4LVmMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/quiz/decembre-ensemble.vue?macro=true";
import { default as generiqueEy0v2BH87PMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/quiz/generique.vue?macro=true";
import { default as mgen073zXy8IrpMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/quiz/mgen.vue?macro=true";
import { default as printemps_45pour_45la_45planeteb5my4VtGEJMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/quiz/printemps-pour-la-planete.vue?macro=true";
import { default as septembre_45pour_45apprendregdLGeQ972DMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/quiz/septembre-pour-apprendre.vue?macro=true";
import { default as _91slug_93ts158OnNLyMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/reseaux/[slug].vue?macro=true";
import { default as indexkQoltplSHAMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/stats/index.vue?macro=true";
import { default as missions3GC1qnVpvAMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/stats/missions.vue?macro=true";
import { default as organisations7pnLoCvthyMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/stats/organisations.vue?macro=true";
import { default as participationsLgJYxf36H5Meta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/stats/participations.vue?macro=true";
import { default as placesMfPUR5mNXKMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/stats/places.vue?macro=true";
import { default as utilisateursCejjVPVZhwMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/stats/utilisateurs.vue?macro=true";
import { default as search_45archived_45usersdnuNJwwl8MMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/support/actions/search-archived-users.vue?macro=true";
import { default as transfert_45organisationQMPhGBpuA7Meta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/support/actions/transfert-organisation.vue?macro=true";
import { default as user_45reset_45context_45rolealkie8evIhMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/support/actions/user-reset-context-role.vue?macro=true";
import { default as user_45reset_45password_45linkjj06xCCfSSMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/support/actions/user-reset-password-link.vue?macro=true";
import { default as doublons_45organisationshdhhmqymXpMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/support/contents/doublons-organisations.vue?macro=true";
import { default as doublons_45territoiresPogMYE9jktMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/support/contents/doublons-territoires.vue?macro=true";
import { default as indexpotiatqZ5NMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/support/index.vue?macro=true";
import { default as activity_45logsb5ZDmidKZtMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/support/referents/activity-logs.vue?macro=true";
import { default as waiting_45actionsjjfCIem2bWMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/support/referents/waiting-actions.vue?macro=true";
import { default as missions_45outdatedToQSLh2v85Meta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/support/responsables/missions-outdated.vue?macro=true";
import { default as participations_45to_45be_45treatedXbcu2iShcVMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/support/responsables/participations-to-be-treated.vue?macro=true";
import { default as _91token_93WwKizUVNNSMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/temoignages/[token].vue?macro=true";
import { default as indexNhZ7BMc5rOMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/territoires/index.vue?macro=true";
import { default as _91slug_93DY97aSESlWMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/villes/[slug].vue?macro=true";
import { default as component_45stubsgDUNDjtwjMeta } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubsgDUNDjtwj } from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "accessibilite",
    path: "/accessibilite",
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/accessibilite.vue").then(m => m.default || m)
  },
  {
    name: "activites-slug",
    path: "/activites/:slug()",
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/activites/[slug].vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-activites-id-edit",
    path: "/admin/contenus/activites/:id()/edit",
    meta: editba6dtf5ZvvMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/contenus/activites/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-activites-id",
    path: "/admin/contenus/activites/:id()",
    meta: indexSuCoiDGl01Meta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/contenus/activites/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-activites-add",
    path: "/admin/contenus/activites/add",
    meta: addU7s2ZdIc43Meta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/contenus/activites/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-activites",
    path: "/admin/contenus/activites",
    meta: indexaFHMgiRTp4Meta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/contenus/activites/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-domaines-id-edit",
    path: "/admin/contenus/domaines/:id()/edit",
    meta: editI8DUtw9m2BMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/contenus/domaines/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-domaines-add",
    path: "/admin/contenus/domaines/add",
    meta: addUP3KeyRzNsMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/contenus/domaines/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-domaines",
    path: "/admin/contenus/domaines",
    meta: index8APmwzgprbMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/contenus/domaines/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-modeles-mission-id-edit",
    path: "/admin/contenus/modeles-mission/:id()/edit",
    meta: editZr24pgVJR0Meta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/contenus/modeles-mission/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-modeles-mission-add",
    path: "/admin/contenus/modeles-mission/add",
    meta: addSgz5ehxJY7Meta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/contenus/modeles-mission/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-modeles-mission",
    path: "/admin/contenus/modeles-mission",
    meta: indexFyEryBKTjeMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/contenus/modeles-mission/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-reseaux-id-edit",
    path: "/admin/contenus/reseaux/:id()/edit",
    meta: editaNtW7hBW7uMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/contenus/reseaux/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-reseaux-id",
    path: "/admin/contenus/reseaux/:id()",
    meta: indexTa1VTJnjuZMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/contenus/reseaux/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-reseaux-add",
    path: "/admin/contenus/reseaux/add",
    meta: addiHobtq8Ji6Meta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/contenus/reseaux/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-reseaux",
    path: "/admin/contenus/reseaux",
    meta: indexRAdx9gpKIzMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/contenus/reseaux/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-ressources-id-edit",
    path: "/admin/contenus/ressources/:id()/edit",
    meta: editHdeTXNUenVMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/contenus/ressources/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-ressources-add",
    path: "/admin/contenus/ressources/add",
    meta: addA484RCsuN9Meta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/contenus/ressources/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-ressources",
    path: "/admin/contenus/ressources",
    meta: indextVEqehXNeMMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/contenus/ressources/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-territoires-id-edit",
    path: "/admin/contenus/territoires/:id()/edit",
    meta: editulJc7xj0XTMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/contenus/territoires/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-territoires-id",
    path: "/admin/contenus/territoires/:id()",
    meta: indexEvLv1MZPlHMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/contenus/territoires/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-territoires-add",
    path: "/admin/contenus/territoires/add",
    meta: add1aJCV5fy9TMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/contenus/territoires/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-territoires",
    path: "/admin/contenus/territoires",
    meta: indexHOayaJTzMEMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/contenus/territoires/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-testimonials-id-edit",
    path: "/admin/contenus/testimonials/:id()/edit",
    meta: editLyNrzNG258Meta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/contenus/testimonials/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-testimonials",
    path: "/admin/contenus/testimonials",
    meta: index1ksGEPZVWOMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/contenus/testimonials/index.vue").then(m => m.default || m)
  },
  {
    name: "admin",
    path: "/admin",
    meta: indextzNWbb3cLUMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-missions-id-benevoles-informations",
    path: "/admin/missions/:id()/benevoles-informations",
    meta: benevoles_45informationsI0wz0vBHY7Meta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/missions/[id]/benevoles-informations.vue").then(m => m.default || m)
  },
  {
    name: "admin-missions-id-benevoles",
    path: "/admin/missions/:id()/benevoles",
    meta: benevolesLl9vBSaQUJMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/missions/[id]/benevoles.vue").then(m => m.default || m)
  },
  {
    name: "admin-missions-id-dates",
    path: "/admin/missions/:id()/dates",
    meta: datesjI1MdicvkkMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/missions/[id]/dates.vue").then(m => m.default || m)
  },
  {
    name: "admin-missions-id",
    path: "/admin/missions/:id()",
    meta: indexWx2K9qPACqMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/missions/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-missions-id-informations",
    path: "/admin/missions/:id()/informations",
    meta: informationsMM5PxVyPNnMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/missions/[id]/informations.vue").then(m => m.default || m)
  },
  {
    name: "admin-missions-id-lieux",
    path: "/admin/missions/:id()/lieux",
    meta: lieux8Z8FkQcdCnMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/missions/[id]/lieux.vue").then(m => m.default || m)
  },
  {
    name: "admin-missions-id-responsables",
    path: "/admin/missions/:id()/responsables",
    meta: responsablesyp2tf0P1UNMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/missions/[id]/responsables.vue").then(m => m.default || m)
  },
  {
    name: "admin-missions-id-title",
    path: "/admin/missions/:id()/title",
    meta: titlefzWMvXINyMMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/missions/[id]/title.vue").then(m => m.default || m)
  },
  {
    name: "admin-missions-id-trouver-des-benevoles",
    path: "/admin/missions/:id()/trouver-des-benevoles",
    meta: trouver_45des_45benevolesLKbpCN3mXVMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/missions/[id]/trouver-des-benevoles.vue").then(m => m.default || m)
  },
  {
    name: "admin-missions-id-visuel",
    path: "/admin/missions/:id()/visuel",
    meta: visuelBmBYsksht2Meta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/missions/[id]/visuel.vue").then(m => m.default || m)
  },
  {
    name: "admin-missions",
    path: "/admin/missions",
    meta: indexJ86KKZ8s6rMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/missions/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-notes",
    path: "/admin/notes",
    meta: notesitNUMexM7GMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/notes.vue").then(m => m.default || m)
  },
  {
    name: "admin-organisations-id-edit",
    path: "/admin/organisations/:id()/edit",
    meta: editnnBUdC0OmhMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/organisations/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-organisations-id",
    path: "/admin/organisations/:id()",
    meta: indexI5MNd2WYQCMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/organisations/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-organisations-id-missions-add",
    path: "/admin/organisations/:id()/missions/add",
    meta: add8KqeILywl5Meta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/organisations/[id]/missions/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-organisations",
    path: "/admin/organisations",
    meta: indexGLl7ftHas3Meta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/organisations/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-other-invitations",
    path: "/admin/other/invitations",
    meta: invitationsJTbaAt4XtNMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/other/invitations.vue").then(m => m.default || m)
  },
  {
    name: "admin-other-logs",
    path: "/admin/other/logs",
    meta: logsQ53mkMWZjbMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/other/logs.vue").then(m => m.default || m)
  },
  {
    name: "admin-participations",
    path: "/admin/participations",
    meta: indexXTXZyQxiSjMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/participations/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-ressources",
    path: "/admin/ressources",
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/ressources.vue").then(m => m.default || m)
  },
  {
    name: "admin-settings-emails",
    path: "/admin/settings/emails",
    meta: emailsNqvQnh7qVyMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/settings/emails.vue").then(m => m.default || m)
  },
  {
    name: "admin-settings-general",
    path: "/admin/settings/general",
    meta: generalKKvZzegFYYMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/settings/general.vue").then(m => m.default || m)
  },
  {
    name: "admin-settings-messages",
    path: "/admin/settings/messages",
    meta: messagess4UcmkoHSZMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/settings/messages.vue").then(m => m.default || m)
  },
  {
    name: "admin-settings-rules-id-edit",
    path: "/admin/settings/rules/:id()/edit",
    meta: editDTreb1IzwAMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/settings/rules/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-settings-rules-id-pending-items",
    path: "/admin/settings/rules/:id()/pending-items",
    meta: pending_45itemsRkbTOXnpyRMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/settings/rules/[id]/pending-items.vue").then(m => m.default || m)
  },
  {
    name: "admin-settings-rules-add",
    path: "/admin/settings/rules/add",
    meta: addCQXTSFesDOMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/settings/rules/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-settings-rules",
    path: "/admin/settings/rules",
    meta: indexC6EgFNEcb6Meta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/settings/rules/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-api-engagement-trafic-entrant",
    path: "/admin/statistics/api-engagement/trafic-entrant",
    meta: trafic_45entrantmUl5jmmeZoMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/statistics/api-engagement/trafic-entrant.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-api-engagement-trafic-sortant",
    path: "/admin/statistics/api-engagement/trafic-sortant",
    meta: trafic_45sortant0kkuCMinZYMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/statistics/api-engagement/trafic-sortant.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-conversions",
    path: "/admin/statistics/conversions",
    meta: conversionscF9Ui5io5BMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/statistics/conversions.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-departements-missions-a-valider",
    path: "/admin/statistics/departements/missions-a-valider",
    meta: missions_45a_45validerEfK8ZsdxcaMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/statistics/departements/missions-a-valider.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-departements-missions-perimees",
    path: "/admin/statistics/departements/missions-perimees",
    meta: missions_45perimeesYWXSueFprSMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/statistics/departements/missions-perimees.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-departements-organisations-a-valider",
    path: "/admin/statistics/departements/organisations-a-valider",
    meta: organisations_45a_45validerVwLvCHxk7LMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/statistics/departements/organisations-a-valider.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics",
    path: "/admin/statistics",
    meta: indexafz1jgfcoeMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/statistics/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-indicateurs-cles",
    path: "/admin/statistics/indicateurs-cles",
    meta: indicateurs_45clesFL0OneSz7bMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/statistics/indicateurs-cles.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-missions",
    path: "/admin/statistics/missions",
    meta: missionsVcpxWKZPHYMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/statistics/missions.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-organisations",
    path: "/admin/statistics/organisations",
    meta: indexQPpQ2Ys7u9Meta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/statistics/organisations/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-organisations-missions-perimees",
    path: "/admin/statistics/organisations/missions-perimees",
    meta: missions_45perimeeskBmWL7vwUJMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/statistics/organisations/missions-perimees.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-organisations-participations-a-valider",
    path: "/admin/statistics/organisations/participations-a-valider",
    meta: participations_45a_45validerSDoImJChFLMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/statistics/organisations/participations-a-valider.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-organisations-participations-refusees-annulees",
    path: "/admin/statistics/organisations/participations-refusees-annulees",
    meta: participations_45refusees_45annuleesqw8Ah5c9XBMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/statistics/organisations/participations-refusees-annulees.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-participations",
    path: "/admin/statistics/participations",
    meta: participations8O6XjDdnyVMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/statistics/participations.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-places",
    path: "/admin/statistics/places",
    meta: placesz9JV2xpLjbMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/statistics/places.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-suivis-moderations",
    path: "/admin/statistics/suivis/moderations",
    meta: moderationsSr4SNIyzg5Meta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/statistics/suivis/moderations.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-utilisateurs",
    path: "/admin/statistics/utilisateurs",
    meta: utilisateursbuZ52yisvpMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/statistics/utilisateurs.vue").then(m => m.default || m)
  },
  {
    name: "admin-taxonomies-slug-id-edit",
    path: "/admin/taxonomies/:slug()/:id()/edit",
    meta: edit8VWuCbyuIbMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/taxonomies/[slug]/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-taxonomies-slug-add",
    path: "/admin/taxonomies/:slug()/add",
    meta: addHuzqqiq5eKMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/taxonomies/[slug]/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-taxonomies-slug",
    path: "/admin/taxonomies/:slug()",
    meta: indexvxhvPg3EJjMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/taxonomies/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-temoignages",
    path: "/admin/temoignages",
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/temoignages.vue").then(m => m.default || m)
  },
  {
    name: "admin-utilisateurs-id-edit",
    path: "/admin/utilisateurs/:id()/edit",
    meta: edit4EJNrwx7Y5Meta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/utilisateurs/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-utilisateurs-id",
    path: "/admin/utilisateurs/:id()",
    meta: indexQpf0wTn9mQMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/utilisateurs/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-utilisateurs",
    path: "/admin/utilisateurs",
    meta: indexNV0G9uF9PuMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/admin/utilisateurs/index.vue").then(m => m.default || m)
  },
  {
    name: "charte-bon-fonctionnement-benevoles",
    path: "/charte-bon-fonctionnement/benevoles",
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/charte-bon-fonctionnement/benevoles.vue").then(m => m.default || m)
  },
  {
    name: "charte-bon-fonctionnement",
    path: "/charte-bon-fonctionnement",
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/charte-bon-fonctionnement/index.vue").then(m => m.default || m)
  },
  {
    name: "charte-bon-fonctionnement-responsables",
    path: "/charte-bon-fonctionnement/responsables",
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/charte-bon-fonctionnement/responsables.vue").then(m => m.default || m)
  },
  {
    name: "charte-reserve-civique",
    path: "/charte-reserve-civique",
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/charte-reserve-civique.vue").then(m => m.default || m)
  },
  {
    name: "conditions-generales-d-utilisation",
    path: "/conditions-generales-d-utilisation",
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/conditions-generales-d-utilisation.vue").then(m => m.default || m)
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: indexP013I2MabzMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "departements-slug",
    path: "/departements/:slug()",
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/departements/[slug].vue").then(m => m.default || m)
  },
  {
    name: "domaines-action-slug",
    path: "/domaines-action/:slug()",
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/domaines-action/[slug].vue").then(m => m.default || m)
  },
  {
    name: "en-ce-moment",
    path: "/en-ce-moment",
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/en-ce-moment.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "inscription-benevole",
    path: "/inscription/benevole",
    meta: indexKWxfd6hWYvMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/inscription/benevole/index.vue").then(m => m.default || m)
  },
  {
    name: "inscription-benevole-step-competences",
    path: "/inscription/benevole/step/competences",
    meta: competencesvJlYa9Fv9CMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/inscription/benevole/step/competences.vue").then(m => m.default || m)
  },
  {
    name: "inscription-benevole-step-disponibilites",
    path: "/inscription/benevole/step/disponibilites",
    meta: disponibilites8zuSXnD3dDMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/inscription/benevole/step/disponibilites.vue").then(m => m.default || m)
  },
  {
    name: "inscription-benevole-step-preferences",
    path: "/inscription/benevole/step/preferences",
    meta: preferencesyoRdqFCqQuMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/inscription/benevole/step/preferences.vue").then(m => m.default || m)
  },
  {
    name: "inscription-benevole-step-profile",
    path: "/inscription/benevole/step/profile",
    meta: profileDx2iKWNMF8Meta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/inscription/benevole/step/profile.vue").then(m => m.default || m)
  },
  {
    name: "inscription",
    path: "/inscription",
    meta: indexasXdRLSBIFMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/inscription/index.vue").then(m => m.default || m)
  },
  {
    name: "inscription-responsable",
    path: "/inscription/responsable",
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/inscription/responsable/index.vue").then(m => m.default || m)
  },
  {
    name: "inscription-responsable-step-charte-bon-fonctionnement",
    path: "/inscription/responsable/step/charte-bon-fonctionnement",
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/inscription/responsable/step/charte-bon-fonctionnement.vue").then(m => m.default || m)
  },
  {
    name: "inscription-responsable-step-collectivite-confirmation",
    path: "/inscription/responsable/step/collectivite-confirmation",
    meta: collectivite_45confirmation6aogiFgBKpMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/inscription/responsable/step/collectivite-confirmation.vue").then(m => m.default || m)
  },
  {
    name: "inscription-responsable-step-collectivite",
    path: "/inscription/responsable/step/collectivite",
    meta: collectivitewxOWRN25KvMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/inscription/responsable/step/collectivite.vue").then(m => m.default || m)
  },
  {
    name: "inscription-responsable-step-organisation-confirmation",
    path: "/inscription/responsable/step/organisation-confirmation",
    meta: organisation_45confirmationDLo5Pp3LgWMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/inscription/responsable/step/organisation-confirmation.vue").then(m => m.default || m)
  },
  {
    name: "inscription-responsable-step-organisation-details",
    path: "/inscription/responsable/step/organisation-details",
    meta: organisation_45detailsrtNstWtMDUMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/inscription/responsable/step/organisation-details.vue").then(m => m.default || m)
  },
  {
    name: "inscription-responsable-step-organisation-images",
    path: "/inscription/responsable/step/organisation-images",
    meta: organisation_45imageswNf0gX4IA6Meta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/inscription/responsable/step/organisation-images.vue").then(m => m.default || m)
  },
  {
    name: "inscription-responsable-step-organisation",
    path: "/inscription/responsable/step/organisation",
    meta: organisation9JgoQwL7eSMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/inscription/responsable/step/organisation.vue").then(m => m.default || m)
  },
  {
    name: "inscription-responsable-step-profile",
    path: "/inscription/responsable/step/profile",
    meta: profilen98HMQi55FMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/inscription/responsable/step/profile.vue").then(m => m.default || m)
  },
  {
    name: "invitations-hash",
    path: "/invitations/:hash()",
    meta: _91hash_93agdPfIKNPrMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/invitations/[hash].vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginpBxbv48oRCMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "maintenance-admin",
    path: "/maintenance/admin",
    meta: admino2Yf7CTWjcMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/maintenance/admin.vue").then(m => m.default || m)
  },
  {
    name: "maintenance",
    path: "/maintenance",
    meta: indexIDkgWvopmYMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/maintenance/index.vue").then(m => m.default || m)
  },
  {
    name: "mentions-legales",
    path: "/mentions-legales",
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/mentions-legales.vue").then(m => m.default || m)
  },
  {
    name: "messages-id",
    path: "/messages/:id()",
    meta: _91id_93v2hCGoXZ2IMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/messages/[id].vue").then(m => m.default || m)
  },
  {
    name: "messages",
    path: "/messages",
    meta: index8j05Tp35wpMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/messages/index.vue").then(m => m.default || m)
  },
  {
    name: "messages-modeles-add",
    path: "/messages/modeles/add",
    meta: addeJNczDjJ2qMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/messages/modeles/add.vue").then(m => m.default || m)
  },
  {
    name: "messages-modeles",
    path: "/messages/modeles",
    meta: indextH4Hj8WLLjMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/messages/modeles/index.vue").then(m => m.default || m)
  },
  {
    name: "missions-benevolat-id-slug",
    path: "/missions-benevolat/:id()/:slug()",
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/missions-benevolat/[id]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "missions-benevolat-id",
    path: "/missions-benevolat/:id()",
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/missions-benevolat/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "missions-benevolat",
    path: "/missions-benevolat",
    meta: indexIEzEVqIvXbMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/missions-benevolat/index.vue").then(m => m.default || m)
  },
  {
    name: "notifications",
    path: "/notifications",
    meta: notificationspJX0GnXU1pMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/notifications.vue").then(m => m.default || m)
  },
  {
    name: "organisations-slug",
    path: "/organisations/:slug()",
    meta: _91slug_93iwAQhzmwaZMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/organisations/[slug].vue").then(m => m.default || m)
  },
  {
    name: "organisations",
    path: "/organisations",
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/organisations/index.vue").then(m => m.default || m)
  },
  {
    name: "password-reset-token",
    path: "/password-reset/:token()",
    meta: _91token_937Sth7jcFnZMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/password-reset/[token].vue").then(m => m.default || m)
  },
  {
    name: "password-reset",
    path: "/password-reset",
    meta: indexOIOyqvqAixMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/password-reset/index.vue").then(m => m.default || m)
  },
  {
    name: "plan-du-site",
    path: "/plan-du-site",
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/plan-du-site.vue").then(m => m.default || m)
  },
  {
    name: "politique-de-confidentialite",
    path: "/politique-de-confidentialite",
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/politique-de-confidentialite.vue").then(m => m.default || m)
  },
  {
    name: "profile-charte-bon-fonctionnement",
    path: "/profile/charte-bon-fonctionnement",
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/profile/charte-bon-fonctionnement.vue").then(m => m.default || m)
  },
  {
    name: "profile-edit",
    path: "/profile/edit",
    meta: editOOjQ9e3mraMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/profile/edit.vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "/profile",
    meta: indextb6mxiqUwqMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-missions",
    path: "/profile/missions",
    meta: missionsFVJfKW6lsJMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/profile/missions.vue").then(m => m.default || m)
  },
  {
    name: "profile-notifications",
    path: "/profile/notifications",
    meta: notificationsM8b93iRAlNMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/profile/notifications.vue").then(m => m.default || m)
  },
  {
    name: "profile-preferences",
    path: "/profile/preferences",
    meta: preferencesnYM7HPvMhVMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/profile/preferences.vue").then(m => m.default || m)
  },
  {
    name: "profile-settings",
    path: "/profile/settings",
    meta: settingsRJDkuQJfTWMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/profile/settings.vue").then(m => m.default || m)
  },
  {
    name: "quiz-decembre-ensemble",
    path: "/quiz/decembre-ensemble",
    meta: decembre_45ensembleXhwxrX4LVmMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/quiz/decembre-ensemble.vue").then(m => m.default || m)
  },
  {
    name: "quiz-generique",
    path: "/quiz/generique",
    meta: generiqueEy0v2BH87PMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/quiz/generique.vue").then(m => m.default || m)
  },
  {
    name: "quiz-mgen",
    path: "/quiz/mgen",
    meta: mgen073zXy8IrpMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/quiz/mgen.vue").then(m => m.default || m)
  },
  {
    name: "quiz-printemps-pour-la-planete",
    path: "/quiz/printemps-pour-la-planete",
    meta: printemps_45pour_45la_45planeteb5my4VtGEJMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/quiz/printemps-pour-la-planete.vue").then(m => m.default || m)
  },
  {
    name: "quiz-septembre-pour-apprendre",
    path: "/quiz/septembre-pour-apprendre",
    meta: septembre_45pour_45apprendregdLGeQ972DMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/quiz/septembre-pour-apprendre.vue").then(m => m.default || m)
  },
  {
    name: "reseaux-slug",
    path: "/reseaux/:slug()",
    meta: _91slug_93ts158OnNLyMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/reseaux/[slug].vue").then(m => m.default || m)
  },
  {
    name: "stats",
    path: "/stats",
    meta: indexkQoltplSHAMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/stats/index.vue").then(m => m.default || m)
  },
  {
    name: "stats-missions",
    path: "/stats/missions",
    meta: missions3GC1qnVpvAMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/stats/missions.vue").then(m => m.default || m)
  },
  {
    name: "stats-organisations",
    path: "/stats/organisations",
    meta: organisations7pnLoCvthyMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/stats/organisations.vue").then(m => m.default || m)
  },
  {
    name: "stats-participations",
    path: "/stats/participations",
    meta: participationsLgJYxf36H5Meta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/stats/participations.vue").then(m => m.default || m)
  },
  {
    name: "stats-places",
    path: "/stats/places",
    meta: placesMfPUR5mNXKMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/stats/places.vue").then(m => m.default || m)
  },
  {
    name: "stats-utilisateurs",
    path: "/stats/utilisateurs",
    meta: utilisateursCejjVPVZhwMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/stats/utilisateurs.vue").then(m => m.default || m)
  },
  {
    name: "support-actions-search-archived-users",
    path: "/support/actions/search-archived-users",
    meta: search_45archived_45usersdnuNJwwl8MMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/support/actions/search-archived-users.vue").then(m => m.default || m)
  },
  {
    name: "support-actions-transfert-organisation",
    path: "/support/actions/transfert-organisation",
    meta: transfert_45organisationQMPhGBpuA7Meta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/support/actions/transfert-organisation.vue").then(m => m.default || m)
  },
  {
    name: "support-actions-user-reset-context-role",
    path: "/support/actions/user-reset-context-role",
    meta: user_45reset_45context_45rolealkie8evIhMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/support/actions/user-reset-context-role.vue").then(m => m.default || m)
  },
  {
    name: "support-actions-user-reset-password-link",
    path: "/support/actions/user-reset-password-link",
    meta: user_45reset_45password_45linkjj06xCCfSSMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/support/actions/user-reset-password-link.vue").then(m => m.default || m)
  },
  {
    name: "support-contents-doublons-organisations",
    path: "/support/contents/doublons-organisations",
    meta: doublons_45organisationshdhhmqymXpMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/support/contents/doublons-organisations.vue").then(m => m.default || m)
  },
  {
    name: "support-contents-doublons-territoires",
    path: "/support/contents/doublons-territoires",
    meta: doublons_45territoiresPogMYE9jktMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/support/contents/doublons-territoires.vue").then(m => m.default || m)
  },
  {
    name: "support",
    path: "/support",
    meta: indexpotiatqZ5NMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: "support-referents-activity-logs",
    path: "/support/referents/activity-logs",
    meta: activity_45logsb5ZDmidKZtMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/support/referents/activity-logs.vue").then(m => m.default || m)
  },
  {
    name: "support-referents-waiting-actions",
    path: "/support/referents/waiting-actions",
    meta: waiting_45actionsjjfCIem2bWMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/support/referents/waiting-actions.vue").then(m => m.default || m)
  },
  {
    name: "support-responsables-missions-outdated",
    path: "/support/responsables/missions-outdated",
    meta: missions_45outdatedToQSLh2v85Meta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/support/responsables/missions-outdated.vue").then(m => m.default || m)
  },
  {
    name: "support-responsables-participations-to-be-treated",
    path: "/support/responsables/participations-to-be-treated",
    meta: participations_45to_45be_45treatedXbcu2iShcVMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/support/responsables/participations-to-be-treated.vue").then(m => m.default || m)
  },
  {
    name: "temoignages-token",
    path: "/temoignages/:token()",
    meta: _91token_93WwKizUVNNSMeta || {},
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/temoignages/[token].vue").then(m => m.default || m)
  },
  {
    name: "territoires",
    path: "/territoires",
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/territoires/index.vue").then(m => m.default || m)
  },
  {
    name: "villes-slug",
    path: "/villes/:slug()",
    component: () => import("/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/pages/villes/[slug].vue").then(m => m.default || m)
  },
  {
    name: component_45stubsgDUNDjtwjMeta?.name,
    path: "/register/volontaire/:pathMatch(.*)",
    component: component_45stubsgDUNDjtwj
  },
  {
    name: component_45stubsgDUNDjtwjMeta?.name,
    path: "/register/responsable/:pathMatch(.*)",
    component: component_45stubsgDUNDjtwj
  },
  {
    name: component_45stubsgDUNDjtwjMeta?.name,
    path: "/user/settings",
    component: component_45stubsgDUNDjtwj
  },
  {
    name: component_45stubsgDUNDjtwjMeta?.name,
    path: "/user/:pathMatch(.*)",
    component: component_45stubsgDUNDjtwj
  },
  {
    name: component_45stubsgDUNDjtwjMeta?.name,
    path: "/dashboard/structure/:pathMatch(.*)",
    component: component_45stubsgDUNDjtwj
  },
  {
    name: component_45stubsgDUNDjtwjMeta?.name,
    path: "/dashboard/mission/:pathMatch(.*)",
    component: component_45stubsgDUNDjtwj
  },
  {
    name: component_45stubsgDUNDjtwjMeta?.name,
    path: "/dashboard/participation/:pathMatch(.*)",
    component: component_45stubsgDUNDjtwj
  },
  {
    name: component_45stubsgDUNDjtwjMeta?.name,
    path: "/dashboard/profile/:pathMatch(.*)",
    component: component_45stubsgDUNDjtwj
  },
  {
    name: component_45stubsgDUNDjtwjMeta?.name,
    path: "/dashboard/reseaux/:pathMatch(.*)",
    component: component_45stubsgDUNDjtwj
  },
  {
    name: component_45stubsgDUNDjtwjMeta?.name,
    path: "/inscription/organisation",
    component: component_45stubsgDUNDjtwj
  },
  {
    name: component_45stubsgDUNDjtwjMeta?.name,
    path: "/statistiques",
    component: component_45stubsgDUNDjtwj
  },
  {
    name: component_45stubsgDUNDjtwjMeta?.name,
    path: "/activites/accompagnement-aux-activites-sportives",
    component: component_45stubsgDUNDjtwj
  },
  {
    name: component_45stubsgDUNDjtwjMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubsgDUNDjtwj
  },
  {
    name: component_45stubsgDUNDjtwjMeta?.name,
    path: "/pages-sitemap.xml",
    component: component_45stubsgDUNDjtwj
  },
  {
    name: component_45stubsgDUNDjtwjMeta?.name,
    path: "/missions-sitemap.xml",
    component: component_45stubsgDUNDjtwj
  },
  {
    name: component_45stubsgDUNDjtwjMeta?.name,
    path: "/organisations-sitemap.xml",
    component: component_45stubsgDUNDjtwj
  },
  {
    name: component_45stubsgDUNDjtwjMeta?.name,
    path: "/villes-sitemap.xml",
    component: component_45stubsgDUNDjtwj
  },
  {
    name: component_45stubsgDUNDjtwjMeta?.name,
    path: "/departements-sitemap.xml",
    component: component_45stubsgDUNDjtwj
  },
  {
    name: component_45stubsgDUNDjtwjMeta?.name,
    path: "/reseaux-sitemap.xml",
    component: component_45stubsgDUNDjtwj
  },
  {
    name: component_45stubsgDUNDjtwjMeta?.name,
    path: "/activites-sitemap.xml",
    component: component_45stubsgDUNDjtwj
  }
]