import revive_payload_client_4sVQNw7RlN from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import floating_vue_EIcJ7xiw0h from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/.nuxt/floating-vue.mjs";
import plugin_Jozdw60ZsE from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import strapi_CICqBBANNd from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/node_modules/@nuxtjs/strapi/dist/runtime/plugins/strapi.mjs";
import stores_BlraoXqTo0 from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/plugins/stores.ts";
import labels_hYOjbEhb51 from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/plugins/labels.ts";
import filters_VNgPjx6zHj from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/plugins/filters.ts";
import algolia_9jd9dIbZwL from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/plugins/algolia.ts";
import api_engagement_client_F8Ll0GNTLE from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/plugins/api-engagement.client.ts";
import axeptio_client_5lFU7uNISd from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/plugins/axeptio.client.ts";
import calendar_7C5YHq9ZuD from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/plugins/calendar.ts";
import error_handler_kEP5FliEXj from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/plugins/error-handler.ts";
import flare_client_8OewLYGJqN from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/plugins/flare.client.ts";
import floating_vue_client_2c4Sn6eaXt from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/plugins/floating-vue.client.ts";
import gtm_cXsUZobmSF from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/plugins/gtm.ts";
import numeral_2uTwz2q7of from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/plugins/numeral.ts";
import plausible_13apjHIZQ3 from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/plugins/plausible.ts";
import scroll_lock_xPcbcpndcQ from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/plugins/scroll-lock.ts";
import toast_ysMjUcU7eJ from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/plugins/toast.ts";
import utils_EEhlE5KdlH from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/plugins/utils.ts";
import utms_client_TkpvGO4cYf from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/plugins/utms.client.ts";
import vue_click_outside_WxTYXKWfVo from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/plugins/vue-click-outside.ts";
import vue_observe_visibility_eJ2HjGsWX9 from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/plugins/vue-observe-visibility.ts";
import vue_remix_icons_bzCCECAlUk from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/plugins/vue-remix-icons.ts";
import vue_scrollto_lZXr206Htt from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/plugins/vue-scrollto.ts";
import vue_snip_nFNJ2EgT6o from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/plugins/vue-snip.ts";
import vue_star_rating_client_9DRt8kgIkp from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/plugins/vue-star-rating.client.ts";
import vue3_mq_w8uKXeQoCw from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/plugins/vue3-mq.js";
import yup_OM8D6DMeq7 from "/build/2824ad8f-b3fe-4a97-a671-996bf53460ff/plugins/yup.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  floating_vue_EIcJ7xiw0h,
  plugin_Jozdw60ZsE,
  strapi_CICqBBANNd,
  stores_BlraoXqTo0,
  labels_hYOjbEhb51,
  filters_VNgPjx6zHj,
  algolia_9jd9dIbZwL,
  api_engagement_client_F8Ll0GNTLE,
  axeptio_client_5lFU7uNISd,
  calendar_7C5YHq9ZuD,
  error_handler_kEP5FliEXj,
  flare_client_8OewLYGJqN,
  floating_vue_client_2c4Sn6eaXt,
  gtm_cXsUZobmSF,
  numeral_2uTwz2q7of,
  plausible_13apjHIZQ3,
  scroll_lock_xPcbcpndcQ,
  toast_ysMjUcU7eJ,
  utils_EEhlE5KdlH,
  utms_client_TkpvGO4cYf,
  vue_click_outside_WxTYXKWfVo,
  vue_observe_visibility_eJ2HjGsWX9,
  vue_remix_icons_bzCCECAlUk,
  vue_scrollto_lZXr206Htt,
  vue_snip_nFNJ2EgT6o,
  vue_star_rating_client_9DRt8kgIkp,
  vue3_mq_w8uKXeQoCw,
  yup_OM8D6DMeq7
]